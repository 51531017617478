<template>
  <b-container fluid class="px-0">
    <b-card>
      <b-row>
        <b-col cols="6">
          <span>{{ lang("t_queues") }}</span>
          <v-select
            class="mt-50"
            v-model="selected_queues"
            :reduce="(option) => option.internal_name"
            label="display_name"
            value="internal_name"
            :options="queues"
            :placeholder="lang('t_queue')"
            multiple
            :closeOnSelect="false"
            @input="
              () => {
                get_live_agent();
                get_inbound_statistics();
              }
            "
          >
            <template #list-header>
              <div class="w-100 mb-50">
                <b-button
                  class="w-50"
                  size="sm"
                  variant="outline-primary"
                  @click="
                    () => {
                      selected_queues = queues.map((e) => e.internal_name);
                      get_live_agent();
                      get_inbound_statistics();
                    }
                  "
                >
                  <feather-icon size="14" icon="CheckCircleIcon" />
                  {{ lang("t_selectAll") }}
                </b-button>
                <b-button
                  class="w-50"
                  size="sm"
                  variant="outline-danger"
                  @click="
                    () => {
                      selected_queues = [];
                    }
                  "
                >
                  <feather-icon size="14" icon="XOctagonIcon" />
                  {{ lang("t_cleanSelections") }}
                </b-button>
              </div>
            </template>
          </v-select>
        </b-col>
        <b-col cols="6">
          <span>{{ lang("t_queueGroup") }}</span>
          <v-select
            class="mt-50"
            v-model="selected_queue_group"
            :reduce="(option) => option"
            label="display_name"
            :options="queue_groups"
            :placeholder="lang('t_queueGroup')"
            multiple
            :closeOnSelect="false"
            @input="queue_group_changed"
          >
          </v-select>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col>
        <b-card style="height: 10vh" no-body>
          <b-card-text>
            <vue-apex-charts
              height="100%"
              width="100%"
              type="radialBar"
              class="my-1"
              :options="goalOverviewRadialBar"
              :series="avg_productivity"
            />
          </b-card-text>
        </b-card>
      </b-col>
      <b-col v-for="(item, i) in top_bar_info" :key="i">
        <b-card style="height: 10vh" no-body>
          <b-card-text
            class="mx-1 my-1 d-flex justify-content-between align-items-center"
          >
            <div class="truncate">
              <h2 class="mb-25 font-weight-bolder">
                {{ item.value }}
              </h2>
              <span>{{ item.display_name }}</span>
            </div>
            <b-badge
              :variant="
                item.color == 'positive' ? 'light-success' : 'light-danger'
              "
              >{{ item.rate }}</b-badge
            >
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="5">
        <b-card style="height: 30vh" no-body>
          <b-card-title class="mx-1 mt-1"
            ><h4>{{ lang("t_callStatistics") }}</h4></b-card-title
          >
          <b-card-text class="mx-1">
            <b-row class="mb-0">
              <b-col cols="5">
                <div class="d-flex align-items-center mb-1">
                  <span style="font-size: 2.5rem; font-weight: bold">{{
                    today.total
                  }}</span>
                  <b-badge class="pb-50 ml-50" variant="light-success"
                    >{{ call_count_rate }}%</b-badge
                  >
                </div>
                <!-- <span style="font-size: 0.9rem" class="text-muted">
                  Genel çağrı sayısı ortalaması geçen haftaya göre düşüştedir.
                </span> -->
              </b-col>
              <b-col cols="7">
                <vue-apex-charts
                  type="bar"
                  height="100vh"
                  :options="salesBar.chartOptions"
                  :series="salesBar.series"
                />
              </b-col>
            </b-row>
            <b-row class="mx-0 py-1 border">
              <b-col>
                <h5>
                  <feather-icon
                    class="text-success mr-50"
                    icon="PhoneIcon"
                  ></feather-icon>

                  {{ lang("t_inbound") }}
                </h5>
                <h3>{{ today.inbound }}</h3>
                <b-progress
                  show-progress
                  style="height: 1.3vh"
                  :value="today.inbound"
                  :max="today.total"
                  class="progress-bar-secondary"
                  variant="success"
                />
              </b-col>
              <b-col>
                <h5>
                  <feather-icon
                    class="text-warning mr-50"
                    icon="PhoneIcon"
                  ></feather-icon>

                  {{ lang("t_outbound") }}
                </h5>
                <h3>{{ today.outbound }}</h3>
                <b-progress
                  show-progress
                  style="height: 1.3vh"
                  :value="today.outbound"
                  :max="today.total"
                  class="progress-bar-secondary"
                  variant="warning"
                />
              </b-col>
              <b-col>
                <h5>
                  <feather-icon
                    class="text-danger mr-50"
                    icon="PhoneIcon"
                  ></feather-icon>

                  {{ lang("t_dialer") }}
                </h5>
                <h3>{{ today.dialer }}</h3>
                <b-progress
                  show-progress
                  style="height: 1.3vh"
                  :value="today.dialer"
                  :max="today.total"
                  class="progress-bar-secondary"
                  variant="danger"
                />
              </b-col>
            </b-row>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col cols="7">
        <b-card no-body style="height: 30vh; overflow-y: auto">
          <b-table
            :fields="fields"
            :items="tableData"
            responsive="sm"
            small
            striped
            hover
          >
            <template #cell(Productivity)="data">
              <b-progress
                show-value
                :value="data.value"
                :max="100"
                :variant="parseInt(data.value) > 40 ? 'success' : 'warning'"
                striped
              />
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card style="height: 22vh" no-body>
          <b-card-title class="mx-1 mt-1 mb-0"
            ><h4>{{ lang("t_callDetails") }}</h4></b-card-title
          >
          <vue-apex-charts
            type="area"
            height="170"
            :options="lineAreaChartSpline.chartOptions"
            :series="lineAreaChartSpline.series"
          />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  BProgress,
  VBTooltip,
  BTable,
  BButtonGroup,
  BFormInput,
  BButton,
  BBadge,
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BCardSubTitle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import VueApexCharts from "vue-apexcharts";
import ChartjsComponentLineChart from "./Components/ChartjsComponentLineChart.vue";

import { $themeColors } from "@themeConfig";
const chartColors = {
  column: {
    series1: "#826af9",
    series2: "#d2b0ff",
    bg: "#f8d3ff",
  },
  success: {
    shade_100: "#7eefc7",
    shade_200: "#06774f",
  },
  donut: {
    series1: "#ffe700",
    series2: "#00d4bd",
    series3: "#826bf8",
    series4: "#2b9bf4",
    series5: "#FFA1A1",
  },
  area: {
    series3: "#a4f8cd",
    series2: "#60f2ca",
    series1: "#2bdac7",

    // series1: '#60f2ca',
    // series2: '#ffe700',

    // series3: '#FFA1A1',
  },
};
export default {
  components: {
    BCardTitle,
    BCardSubTitle,
    BProgress,
    VBTooltip,
    BTable,
    BButtonGroup,
    BFormInput,
    VueApexCharts,
    Swiper,
    SwiperSlide,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardText,
    BBadge,
    vSelect,
    BButton,
    ChartjsComponentLineChart,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      lineAreaChartSpline: {
        series: [
          {
            name: globalThis._lang("t_inbound"),
            data: [
              120, 180, 170, 110, 180, 100, 90, 180, 160, 140, 200, 220, 275,
              122, 124, 152, 122, 152, 125, 125, 126, 124, 125, 11,
            ],
          },
          {
            name: globalThis._lang("t_outbound"),
            data: [
              100,
              120,
              90,
              170,
              130,
              160,
              140,
              240,
              220,
              180,
              270,
              280,
              375,
              ,
              122,
              124,
              152,
              122,
              152,
              125,
              125,
              126,
              124,
              125,
              11,
            ],
          },

          {
            name: globalThis._lang("t_abandoned"),
            data: [
              20,
              40,
              30,
              70,
              40,
              60,
              50,
              140,
              120,
              100,
              140,
              180,
              220,
              ,
              122,
              124,
              152,
              122,
              152,
              125,
              125,
              126,
              124,
              125,
              11,
            ],
          },
        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            curve: "straight",
          },
          legend: {
            show: true,
            position: "top",
            horizontalAlign: "left",
            fontSize: "12px",
            fontFamily: "Montserrat",
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          xaxis: {
            categories: [
              "0",
              "1",
              "2",
              "3",
              "4",
              "5",
              "6",
              "7",
              "8",
              "9",
              "10",
              "11",
              "12",
              "13",
              "14",
              "15",
              "16",
              "17",
              "18",
              "19",
              "20",
              "21",
              "22",
              "23",
            ],
          },
          yaxis: {
            // opposite: isRtl
          },
          fill: {
            opacity: 1,
            type: "solid",
          },
          tooltip: {
            shared: false,
          },
          colors: [
            chartColors.area.series1,
            chartColors.area.series2,
            chartColors.area.series3,
          ],
        },
      },
      goalOverviewRadialBar: {
        chart: {
          type: "radialBar",
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: [$themeColors.primary],
        plotOptions: {
          radialBar: {
            offsetY: -10,
            startAngle: -90,
            endAngle: 90,
            hollow: {
              size: "77%",
            },
            track: {
              background: $themeColors.secondary,
              strokeWidth: "50%",
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                offsetY: -5,

                // color: $textHeadingColor,
                fontSize: "2.1rem",
                fontWeight: "500",
                paddingTop: "30px",
              },
            },
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.primary],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          lineCap: "round",
        },
        grid: {
          padding: {
            bottom: 30,
          },
        },
      },
      salesBar: {
        series: [
          {
            name: "Daily Call Counts",
            data: [0, 0, 0, 0, 0, 0, 0],
          },
        ],
        chartOptions: {
          chart: {
            sparkline: { enabled: true },
            toolbar: { show: false },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
            },
          },
          states: {
            hover: {
              filter: "none",
            },
          },
          colors: [
            "#ebf0f7",
            "#ebf0f7",
            $themeColors.primary,
            "#ebf0f7",
            "#ebf0f7",
            "#ebf0f7",
          ],
          plotOptions: {
            bar: {
              columnWidth: "45%",
              distributed: true,
              endingShape: "rounded",
            },
          },
          tooltip: {
            x: { show: false },
          },
          xaxis: {
            type: "numeric",
          },
        },
      },
      top_bar_info: [
        {
          display_name: globalThis._lang("t_callTime"),
          value: "4min 20sec",
          rate: "2min 18sec",
          color: "positive",
        },
        {
          display_name: globalThis._lang("t_serviceLevel"),
          value: "%82",
          rate: "%5",
          color: "positive",
        },
        {
          display_name: globalThis._lang("t_abandonRate"),
          value: "%9",
          rate: "%3",
          color: "positive",
        },
        {
          display_name: globalThis._lang("t_callAnswerTime"),
          value: "26sec",
          rate: "8sec",
          color: "positive",
        },
      ],
      today: {
        total: 0,
        inbound: 0,
        outbound: 0,
        dialer: 0,
      },
      yesterday_total: 0,
      call_count_rate: 0,
      daily_call_counts: {
        sun: 0,
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
      },
      projects: [],
      queues: [],
      avg_productivity: [],
      selected_queue_group: [],
      queue_groups: [],
      selected_queues: [],
      selected_project: "",
      visible_users: [],
      finish_codes: [],
      dashboard_data: [],
      bar_charts: [],
      full_screen: false,
      fields: [
        { key: "Agent", label: globalThis._lang("t_agent"), sortable: true },
        {
          key: "Productivity",
          label: globalThis._lang("t_productivity"),
          sortable: true,
        },
        {
          key: "Inbound",
          label: globalThis._lang("t_inbound"),
          sortable: true,
        },
        {
          key: "Outbound",
          label: globalThis._lang("t_outbound"),
          sortable: true,
        },
        { key: "Dialer", label: globalThis._lang("t_dialer"), sortable: true },
        {
          key: "MissedCalls",
          label: globalThis._lang("t_missedCall"),
          sortable: true,
        },
        // { key: 'AvgCallTime', label: globalThis._lang('t_avgCallTime'), sortable: true },
      ],
      tableData: [],

      searchQuery: "",
      interval: "",
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    formatSeconds(seconds) {
      var minutes = Math.floor(seconds / 60);
      var remainingSeconds = seconds % 60;

      // Pad the minutes and seconds with leading zeros, if required
      var paddedMinutes = String(minutes).padStart(1, "0");
      var paddedSeconds = String(remainingSeconds).padStart(1, "0");

      if (minutes > 0) {
        return `${paddedMinutes} min ${paddedSeconds} sec`;
      } else {
        return `${paddedSeconds} sec`;
      }
    },
    queue_group_changed() {
      // console.log("queue_group_changed", this.selected_queue_group)
      let queues = [];
      for (const item of this.selected_queue_group) {
        queues = [...queues, ...item.queues.map((e) => e.internal_name)];
      }
      // console.log("queues", queues)
      this.selected_queues = [];
      this.selected_queues = Array.from(
        new Set([...this.selected_queues, ...queues])
      );
      this.get_live_agent();
    },
    async get_inbound_statistics() {
      if (this.selected_queues.length == 0) {
        this.selected_queues = this.queues.map((e) => e.internal_name);
      }
      var response = (
        await this.$http_in.post(`spv/v1/Dashboard`, {
          selectedQueues: this.selected_queues,
        })
      ).data;
      this.yesterday_total = 0;
      this.today = {
        total: 0,
        inbound: 0,
        outbound: 0,
        dialer: 0,
      };

      this.top_bar_info[0].value = response.TopBarInfo.today.InboundAvgTime;
      this.top_bar_info[1].value =
        "%" + response.TopBarInfo.today.InboundSlSuccessRate;
      this.top_bar_info[2].value =
        response.TopBarInfo.today.InboundFail +
        " (%" +
        response.TopBarInfo.today.InboundFailRate +
        ")";
      this.top_bar_info[3].value =
        response.TopBarInfo.today.InboundAvgCallAnswerTime;
      // Rate Calculateion

      this.top_bar_info[0].rate = this.formatSeconds(
        parseInt(response.TopBarInfo.today.InboundAvgTimeValue) -
        parseInt(response.TopBarInfo.yesterday.InboundAvgTimeValue)
      );
      this.top_bar_info[1].rate =
        "%" +
        Math.ceil(response.TopBarInfo.today.InboundSlSuccessRate -
          response.TopBarInfo.yesterday.InboundSlSuccessRate);
      this.top_bar_info[2].rate =
        "%" +
        Math.ceil(response.TopBarInfo.today.InboundFailRate -
          response.TopBarInfo.yesterday.InboundFailRate);
      this.top_bar_info[3].rate = this.formatSeconds(
        parseInt(response.TopBarInfo.today.InboundAvgCallAnswerTimeValue) -
        parseInt(response.TopBarInfo.yesterday.InboundAvgCallAnswerTimeValue)
      );

      // Color
      console.log(
        "(parseInt(response.TopBarInfo.today.InboundAvgTimeValue) - parseInt(response.TopBarInfo.yesterday.InboundAvgTimeValue))",
        parseInt(response.TopBarInfo.today.InboundAvgTimeValue) -
        parseInt(response.TopBarInfo.yesterday.InboundAvgTimeValue)
      );
      this.top_bar_info[0].color =
        parseInt(response.TopBarInfo.today.InboundAvgTimeValue) -
          parseInt(response.TopBarInfo.yesterday.InboundAvgTimeValue) >
          0
          ? "positive"
          : "negative";
      this.top_bar_info[1].color =
        response.TopBarInfo.today.InboundSlSuccessRate -
          response.TopBarInfo.yesterday.InboundSlSuccessRate >
          0
          ? "positive"
          : "negative";
      this.top_bar_info[2].color =
        response.TopBarInfo.today.InboundFailRate -
          response.TopBarInfo.yesterday.InboundFailRate >
          0
          ? "positive"
          : "negative";
      this.top_bar_info[3].color =
        parseInt(response.TopBarInfo.today.InboundAvgCallAnswerTimeValue) -
          parseInt(
            response.TopBarInfo.yesterday.InboundAvgCallAnswerTimeValue
          ) >
          0
          ? "positive"
          : "negative";

      const today = new Date();
      const dayNames = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
      const todayName = dayNames[today.getDay()];
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      const yesterdayName = dayNames[yesterday.getDay()];
      // let today_counts = response.DailyCounts.filter(e => e.dayOfWeek == todayName)
      this.daily_call_counts = {
        sun: 0,
        mon: 0,
        tue: 0,
        wed: 0,
        thu: 0,
        fri: 0,
        sat: 0,
      };
      for (const item of response.DailyCounts) {
        this.daily_call_counts[item.dayOfWeek] += item.count ?? 0;
        if (item.dayOfWeek == todayName) {
          if (item.direction == "inbound") {
            this.today.inbound = item.count ?? 0;
          }
          if (item.direction == "outbound") {
            this.today.outbound = item.count ?? 0;
          }
          if (item.direction == "dialer") {
            this.today.dialer = item.count ?? 0;
          }
        } else if (item.dayOfWeek == yesterdayName) {
          this.yesterday_total += item.count ?? 0;
        }
      }
      this.today.total =
        this.today.inbound + this.today.outbound + this.today.dialer;
      this.call_count_rate =
        Math.ceil(((this.today.total - this.yesterday_total) / this.yesterday_total) *
          100);
      this.salesBar.series[0].data = Object.values(this.daily_call_counts);
      console.log("salesBar.series[0].data", this.salesBar.series[0].data);

      let _colors = [];
      for (const day of dayNames) {
        if (day == todayName) {
          _colors.push($themeColors.primary);
        } else {
          _colors.push("#ebf0f7");
        }
      }
      this.lineAreaChartSpline.series[0].data = response.BottomBarInfo.Inbound;
      this.lineAreaChartSpline.series[1].data = response.BottomBarInfo.Outbound;
      this.lineAreaChartSpline.series[2].data =
        response.BottomBarInfo.Abandoned;
      console.log(
        "this.lineAreaChartSpline.series",
        this.lineAreaChartSpline.series
      );
      this.lineAreaChartSpline.series = [...this.lineAreaChartSpline.series];
      this.lineAreaChartSpline.chartOptions = JSON.parse(
        JSON.stringify(this.lineAreaChartSpline.chartOptions)
      );
      this.salesBar.chartOptions = {
        ...this.salesBar.chartOptions,
        colors: _colors,
      };
      console.log(response.DailyCounts);
    },
    get_live_agent: async function () {
      var response = (await this.$http_in.get(`qmgr/v1/LiveAgent`)).data;

      this.dashboard_data = {};
      let _avg_productivity = [];

      for (const agent of response.filter(
        (e) => e != null && e.Queues.Value.length > 0
      )) {
        for (const queue of agent.Queues.Value) {
          if (
            this.selected_queues.includes(queue) &&
            this.selected_queues.length > 0
          ) {
            _avg_productivity.push(agent.Productivity.calculated ?? 0);
            if (this.visible_users[agent.Username + "@" + agent.Domain]) {
              if (!this.dashboard_data.hasOwnProperty(agent.Username)) {
                this.dashboard_data[agent.Username] = {
                  Agent: agent.Username,
                  Productivity: agent.Productivity.calculated || 0,
                  Inbound: agent.AcceptedCalls,
                  Outbound: agent.SuccessfulOutboundCalls,
                  Dialer: agent.AcceptedDialerCalls,
                  MissedCalls: agent.MissedDialerCalls + agent.MissedCalls,
                };
              }
            }
            break;
          }
        }

        // for (const agent of response.filter(e => e.Reason != 'Offline')) {
      }
      let sum = _avg_productivity.reduce(
        (total, currentValue) => total + currentValue,
        0
      );

      console.log("_avg_productivity", _avg_productivity);
      this.avg_productivity = [Math.round(sum / _avg_productivity.length)];
      this.tableData = Object.values(this.dashboard_data);
      // console.log("DATA", Object.values(this.dashboard_data));
    },
    get_users: async function () {
      var response = (await this.$http_in.get(`spv/v1/User`)).data;

      for (const row of response) {
        this.visible_users[row.identifier] = row.is_visible_in_live_watch;
      }
    },
    GetQueueGroup: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/QueueGroup`)).data;
      // console.log('queues', response);
      // let p_queues = globalThis.permissions["queue"]

      this.queue_groups = response;
      // for (const item of response) {
      //   if (p_queues.includes(item.internal_name)) {
      //     this.queues.push(item);
      //     this.selected_queues.push(item.internal_name);
      //   }
      // }
    },
    GetQueues: async function () {
      var response = (await this.$http_in.get(`spv/v1/Queue`)).data;
      // console.log('queues', response);
      this.queues = [];
      let p_queues = globalThis.permissions["queue"];
      this.all_queues = response;
      for (const item of response) {
        if (p_queues.includes(item.internal_name)) {
          this.queues.push(item);
          // this.selected_queues.push(item.internal_name);
        } else {
          this.queues.filter((e) => e.internal_name != item.internal_name);
        }
      }
    },
    GetProjects: async function () {
      var response = (await this.$http_in.get(`spv/v1/Project`)).data;

      this.projects = response;
    },
  },
  mounted: async function () {
    let _self = this;
    const this_data = JSON.parse(localStorage.getItem("dashboardData"));
    console.log("this_data", this_data);
    if (![undefined, null].includes(this_data)) {
      for (let [key, val] of Object.entries(this_data)) {
        this[key] = val;
      }
    }

    this.finish_codes = globalThis.finish_codes;
    await this.get_users();

    await this.GetQueues();
    await this.get_inbound_statistics();

    await this.GetQueueGroup();
    // await this.GetProjects();
    await this.get_live_agent();

    // await this.GetDashboardData();

    this.interval = setInterval(() => {
      _self.get_inbound_statistics();
      _self.get_live_agent();
    }, 60000);
  },
  beforeDestroy() {
    localStorage.setItem("dashboardData", JSON.stringify(this.$data));

    clearInterval(this.interval);
  },
};
</script>

<style>
</style>
